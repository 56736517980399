import { NetworkStatus } from '@apollo/client'
import { ChainId, Currency, CurrencyAmount, Price, TradeType } from '@electroswap/sdk-core'
import { nativeOnChain } from 'constants/tokens'
import { Chain, useTokenSpotPriceQuery } from 'graphql/data/__generated__/types-and-hooks'
import { chainIdToBackendName, PollingInterval } from 'graphql/data/util'
import { useMemo } from 'react'
import { ClassicTrade, INTERNAL_ROUTER_PREFERENCE_PRICE, TradeState } from 'state/routing/types'
import { useRoutingAPITrade } from 'state/routing/useRoutingAPITrade'
import { getNativeTokenDBAddress } from 'utils/nativeTokens'

import useStablecoinPrice from './useStablecoinPrice'

// ETN amounts used when calculating spot price for a given currency.
// The amount is large enough to filter low liquidity pairs.
const ETN_AMOUNT_OUT: { [chainId: number]: CurrencyAmount<Currency> } = {
  [ChainId.ELECTRONEUM]: CurrencyAmount.fromRawAmount(nativeOnChain(ChainId.ELECTRONEUM), 1000e18),
  [ChainId.ELECTRONEUM_TEST]: CurrencyAmount.fromRawAmount(nativeOnChain(ChainId.ELECTRONEUM_TEST), 1000e18),
}

function useETNPrice(currency?: Currency): {
  data?: Price<Currency, Currency>
  isLoading: boolean
} {
  const chainId = currency?.chainId
  const isSupported = currency !== undefined && chainId !== undefined

  const amountOut = isSupported ? ETN_AMOUNT_OUT[chainId] : undefined
  const { trade, state } = useRoutingAPITrade(
    !isSupported /* skip */,
    TradeType.EXACT_OUTPUT,
    amountOut,
    currency,
    INTERNAL_ROUTER_PREFERENCE_PRICE
  )

  return useMemo(() => {
    if (!isSupported) {
      return { data: undefined, isLoading: false }
    }

    if (currency?.wrapped.equals(nativeOnChain(chainId).wrapped)) {
      return {
        data: new Price(currency, currency, '1', '1'),
        isLoading: false,
      }
    }

    if (!trade || state === TradeState.LOADING) {
      return { data: undefined, isLoading: state === TradeState.LOADING }
    }

    // if initial quoting fails, we may end up with a DutchOrderTrade
    if (trade && trade instanceof ClassicTrade) {
      const { numerator, denominator } = trade.routes[0].midPrice
      const price = new Price(currency, nativeOnChain(chainId), denominator, numerator)
      return { data: price, isLoading: false }
    }

    return { data: undefined, isLoading: false }
  }, [chainId, currency, isSupported, state, trade])
}

let lastEtnPrice: any = undefined
let timerElapsed = true

function useCachedEtnPrice(chain: Chain | undefined, skip?: boolean) {
  const { data, networkStatus } = useTokenSpotPriceQuery({
    variables: { chain: chain ?? Chain.Electroneum, address: getNativeTokenDBAddress(chain ?? Chain.Electroneum) },
    skip: skip || !timerElapsed,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
  })

  useMemo(() => {
    if (data?.token?.project?.markets?.[0]?.price?.value) {
      timerElapsed = false
      lastEtnPrice = data?.token?.project?.markets?.[0]?.price?.value
      setTimeout(() => {
        timerElapsed = true
      }, PollingInterval.Normal)
    }
  }, [data])

  return {
    lastEtnPrice,
    networkStatus,
  }
}

export function useUSDPrice(
  currencyAmount?: CurrencyAmount<Currency>,
  prefetchCurrency?: Currency
): {
  data?: number
  isLoading: boolean
} {
  const currency = currencyAmount?.currency ?? prefetchCurrency

  const chainId = currency?.chainId
  const chain = chainId ? chainIdToBackendName(chainId) : undefined

  // Use ETN-based pricing if available.
  const { data: tokenEtnPrice, isLoading: isTokenEtnPriceLoading } = useETNPrice(currency)
  const isTokenEtnPriced = Boolean(tokenEtnPrice || isTokenEtnPriceLoading)

  const { lastEtnPrice, networkStatus } = useCachedEtnPrice(chain, !isTokenEtnPriced)
  // const { data, networkStatus } = useTokenSpotPriceQuery({
  //   variables: { chain: chain ?? Chain.Electroneum, address: getNativeTokenDBAddress(chain ?? Chain.Electroneum) },
  //   skip: !isTokenEtnPriced,
  //   pollInterval: PollingInterval.Slow,
  //   notifyOnNetworkStatusChange: true,
  //   fetchPolicy: 'cache-first'
  // })

  // Use USDC-based pricing for chains not yet supported by backend (for ETN-based pricing).
  const stablecoinPrice = useStablecoinPrice(isTokenEtnPriced ? undefined : currency)

  return useMemo(() => {
    if (!currencyAmount) {
      return { data: undefined, isLoading: false }
    } else if (stablecoinPrice) {
      return { data: parseFloat(stablecoinPrice.quote(currencyAmount).toSignificant()), isLoading: false }
    } else {
      // Otherwise, get the price of the token in ETN, and then multiply by the price of ETN.
      const ethUSDPrice = lastEtnPrice
      if (ethUSDPrice && tokenEtnPrice) {
        return { data: parseFloat(tokenEtnPrice.quote(currencyAmount).toExact()) * ethUSDPrice, isLoading: false }
      } else {
        return { data: undefined, isLoading: isTokenEtnPriceLoading || networkStatus === NetworkStatus.loading }
      }
    }
  }, [currencyAmount, lastEtnPrice, tokenEtnPrice, isTokenEtnPriceLoading, networkStatus /*, stablecoinPrice*/])
}
