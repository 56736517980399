export const ELECTROSWAP_LIST = 'https://app.electroswap.io/tokenlist.json'
export const ELECTROSWAP_EXTENDED_LIST = 'https://app.electroswap.io/tokenlist.json'
const ELECTROSWAP_UNSUPPORTED_LIST = 'https://app.electroswap.io/unsupported.tokens.json'

export const UNSUPPORTED_LIST_URLS: string[] = []

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [ELECTROSWAP_LIST]
export const DEFAULT_INACTIVE_LIST_URLS: string[] = [...UNSUPPORTED_LIST_URLS]

export const DEFAULT_LIST_OF_LISTS: string[] = [...DEFAULT_ACTIVE_LIST_URLS, ...DEFAULT_INACTIVE_LIST_URLS]
