import { ChainId, Currency, Electroneum, NativeCurrency, Token, WETN9 } from '@electroswap/sdk-core'

export const NATIVE_CHAIN_ID = 'NATIVE'

// When decimals are not specified for an ERC20 token
// use default ERC20 token decimals as specified here:
// https://docs.openzeppelin.com/contracts/3.x/erc20
export const DEFAULT_ERC20_DECIMALS = 18

export const USDC_ELECTRONEUM = new Token(
  ChainId.ELECTRONEUM,
  '0x74d64C56926E3D758404600B4B6f3954F4216e51',
  6,
  'eUSDC',
  'Wrapped USDC'
)

// eslint-disable-next-line import/no-unused-modules
export const USDC_ELECTRONEUM_TEST = new Token(
  ChainId.ELECTRONEUM_TEST,
  '0xD0aBa3cb8F20146b0C849Db1f5B17e0604670c9B',
  6,
  'eUSDC',
  'Wrapped USDC'
)

export const USDT_ELECTRONEUM = new Token(
  ChainId.ELECTRONEUM,
  '0xD70B4b2e14cBA41fE011ea0c7021B6E64d960d87',
  6,
  'eUSDT',
  'Wrapped USDT'
)

// eslint-disable-next-line import/no-unused-modules
export const USDT_ELECTRONEUM_TEST = new Token(
  ChainId.ELECTRONEUM_TEST,
  '0xD0aBa3cb8F20146b0C849Db1f5B17e0604670c9B',
  6,
  'eUSDT',
  'Wrapped USDT'
)

export const BOLT: { [chainId: number]: Token } = {
  [ChainId.ELECTRONEUM]: new Token(
    ChainId.ELECTRONEUM,
    '0x043fAa1b5C5FC9a7dc35171f290c29ECDE0cCff1',
    18,
    'BOLT',
    'ElectroSwap'
  ),
  [ChainId.ELECTRONEUM_TEST]: new Token(
    ChainId.ELECTRONEUM_TEST,
    '0x8768CcA8591160B423A5b7eFA72842A0AC55382D',
    18,
    'BOLT',
    'ElectroSwap'
  ),
}

export const WRAPPED_NATIVE_CURRENCY: { [chainId: number]: Token } = {
  ...(WETN9 as Record<ChainId, Token>),
}

class ExtendedElectroneum extends Electroneum {
  public get wrapped(): Token {
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    if (wrapped) return wrapped
    throw new Error(`Unsupported chain ID: ${this.chainId}`)
  }

  private static _cachedExtendedEther: { [chainId: number]: NativeCurrency } = {}

  public static onChain(chainId: number): ExtendedElectroneum {
    return this._cachedExtendedEther[chainId] ?? (this._cachedExtendedEther[chainId] = new ExtendedElectroneum(chainId))
  }
}

const cachedNativeCurrency: { [chainId: number]: NativeCurrency | Token } = {}
export function nativeOnChain(chainId: number): NativeCurrency | Token {
  if (cachedNativeCurrency[chainId]) return cachedNativeCurrency[chainId]
  const nativeCurrency: NativeCurrency | Token = ExtendedElectroneum.onChain(chainId)
  return (cachedNativeCurrency[chainId] = nativeCurrency)
}

export function getSwapCurrencyId(currency: Currency): string {
  if (currency.isToken) {
    return currency.address
  }
  return NATIVE_CHAIN_ID
}

export const TOKEN_SHORTHANDS: { [shorthand: string]: { [chainId in ChainId]?: string } } = {
  EUSDC: {
    [ChainId.ELECTRONEUM]: USDC_ELECTRONEUM.address,
    [ChainId.ELECTRONEUM_TEST]: USDC_ELECTRONEUM_TEST.address,
  },
  EUSDT: {
    [ChainId.ELECTRONEUM]: USDT_ELECTRONEUM.address,
    [ChainId.ELECTRONEUM_TEST]: USDT_ELECTRONEUM_TEST.address,
  },
}
